import styled from "styled-components";
import Tag from "./Tag";

const PlaceTag = styled(Tag)`
    background-color: rgb(34 157 168);
    
    @media only screen and (max-width: 40em) {
        display: none;
    }
`;

export default PlaceTag;