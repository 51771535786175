import React, { useEffect, useState } from "react";
import sanityClient from "../client";
import Container from "./atoms/Container";
import styled from "styled-components";
import InfoItem from "./InfoItem";

const InfosContainer = styled(Container)`
  max-width: 670px;
  margin: auto;

  @media only screen and (max-width: 40em) {
    width: 90%;
    margin-top: 2rem;
  }
`;

export default function Project() {
  const [projectData, setProjectData] = useState(null);
  const [pinnedItem, setPinnedItem] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"]  | order(date desc) [0...20] {
            _id,
            title, 
            date,
            place,
            description,
            projectType,
            pinned,
            link, 
            tags
        }`
      )
      .then((data) => setProjectData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    setPinnedItem(projectData?.find((item) => item.pinned));
  }, [projectData]);

  return (
    <InfosContainer>
      <section className="grid grid-cols-1 gap-8 p-4 md:p-12">
        {pinnedItem && <InfoItem item={pinnedItem} isPinned />}
        {projectData &&
          projectData
            .filter((item) => item._id !== pinnedItem?._id)
            .map((project) => <InfoItem item={project} isPinned={false} />)}
      </section>
    </InfosContainer>
  );
}
