import styled from "styled-components";
import sanityClient from "../../../client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const AuthorTagContainer = styled.div`
  color: white;

  @media only screen and (max-width: 40em) {
    display: none;
  }
`;

const AuthorTag = ({ singlePost }) => {
  return (
    <AuthorTagContainer className="flex mr-10">
      <img
        src={urlFor(singlePost.authorImage).url()}
        alt={singlePost.name}
        className="w-8 h-8 rounded-full"
      />
      <p className="bold flex items-center pl-2 text-xl">{singlePost.name}</p>
    </AuthorTagContainer>
  );
};

export default AuthorTag;
