import React, { useEffect} from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import SinglePages from "./components/SinglePost";
import SingleOccasionalPost from "./components/SingleOccasionalPost";
import Tuesday from "./components/Tuesday";
import Occasional from "./components/Occasional";
import Infos from "./components/Infos";
import NavBar from "./components/NavBar";
import Footer from "./components/atoms/Footer";
import Rules from "./components/Rules";

function App() {

  useEffect(() => {
    document.title = "Turnieje Wtorkowe Wrocław";
  }, []);

  return (
    <HashRouter>
      <NavBar />
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={About} path="/info" />
        <Route component={SinglePages} path="/wtorkowe/:slug" />
        <Route component={Tuesday} path="/wtorkowe" />
        <Route component={SingleOccasionalPost} path="/okazjonalne/:slug" />
        <Route component={Occasional} path="/okazjonalne" />
        <Route component={Infos} path="/ogloszenia" />
        <Route component={Rules} path="/regulamin" />
      </Switch>
      <Footer />
    </HashRouter>
  );
}

export default App;
