import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Logo = styled(NavLink)`
    display: inline-flex;
    align-items: items-center;
    font-size: 1.5rem;
    color: #10B981;
`;

  const Navbar = {
    Wrapper: styled.nav`
      flex: 1;
      color: #10B981;
      z-index: 10000;
  
      align-self: flex-start;
  
      padding: 0.25rem 3rem;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      background-color:  #151515;;
  
      // 40em == 640px
      @media only screen and (max-width: 40em) {
        position: fixed;
        width: 100vw;
        top: 0;
        padding: 0.25rem 1rem;
      }
    `,
    Logo: styled.h1`
      padding: 0.5rem 1rem;
      
      @media only screen and (max-width: 40em) {
        padding: 0.5rem 0rem;
      }
    `,
    Items: styled.ul`
      display: flex;
      list-style: none;
  
      @media only screen and (max-width: 40em) {
        position: fixed;
        right: 0;
        bottom: 0;
  
        height: 100%;
        width: 100%;
  
        flex-direction: column;
  
        background-color:  #151515;;
        padding: 1rem 2rem;
  
        transition: 0.2s ease-out;
  
        transform: ${({ openDrawer }) =>
          openDrawer ? `translateX(0)` : `translateX(100%)`};
      }
    `,
    Item: styled.li`
      padding: 0 1rem;
      cursor: pointer;
  
      @media only screen and (max-width: 40em) {
        padding: 1rem 0;
      }
    `
  };
  
  const HamburgerButton = {
    Wrapper: styled.button`
      height: 3rem;
      width: 3rem;
      position: relative;
      font-size: 12px;
  
      display: none;
  
      @media only screen and (max-width: 40em) {
        display: block;
      }
  
      /* Remove default button styles */
      border: none;
      background: transparent;
      outline: none;
  
      cursor: pointer;
  
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 150%;
        width: 150%;
        top: -25%;
        left: -25%;
      }
    `,
    Lines: styled.div`
      top: 50%;
      margin-top: -0.125em;
  
      &,
      &:after,
      &:before {
        /* Create lines */
        height: 1.5px;
        pointer-events: none;
        display: block;
        content: "";
        width: 80%;
        background-color: white;
        position: absolute;
      }
  
      &:after {
        /* Move bottom line below center line */
        top: -0.8rem;
      }
  
      &:before {
        /* Move top line on top of center line */
        top: 0.8rem;
      }
    `
  };

export default function NavBar() {
    const [openDrawer, toggleDrawer] = useState(false);
    const drawerRef = useRef(null);
  
    useEffect(() => {
      const closeDrawer = event => {
        if (drawerRef.current && drawerRef.current.contains(event.target)) {
          return;
        }
  
        toggleDrawer(false);
      };
  
      document.addEventListener("mousedown", closeDrawer);
      return () => document.removeEventListener("mousedown", closeDrawer);
    }, []);  

    return (
      <Navbar.Wrapper>
        <Navbar.Logo>
            <Logo 
                activeClassName="text-white"
                to="/" 
                exact>
                Brydż nad Odrą
            </Logo>
        </Navbar.Logo>

        <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
          <HamburgerButton.Lines />
        </HamburgerButton.Wrapper>

        <Navbar.Items ref={drawerRef} openDrawer={openDrawer} onClick={() => toggleDrawer(false)} >
        <Navbar.Logo hidden={!openDrawer}>
            <Logo 
                activeClassName="text-white"
                to="/" 
                exact>
                Brydż nad Odrą
            </Logo>
        </Navbar.Logo>
          <Navbar.Item>
              <NavLink 
                    className="hover:text-white"
                    activeClassName="text-white"
                    to="/ogloszenia">
                    Ogłoszenia 
               </NavLink>
            </Navbar.Item>
          <Navbar.Item>
              <NavLink 
                    className="hover:text-white"
                    activeClassName="block text-white"
                    to="/wtorkowe">
                    Wtorkowe
                </NavLink>
            </Navbar.Item>
          <Navbar.Item>
              <NavLink 
                            className="hover:text-white"
                            activeClassName="block text-white"
                            to="/okazjonalne">
                            Okazjonalne
                        </NavLink>
                        </Navbar.Item>
          <Navbar.Item>
            <NavLink 
                className="hover:text-white"
                activeClassName="text-white"
                to="/info">
                Informacje
            </NavLink>
          </Navbar.Item>
          <Navbar.Item>
            <NavLink 
                className="hover:text-white"
                activeClassName="text-white"
                to="/regulamin">
                Regulamin
            </NavLink>
          </Navbar.Item>
        </Navbar.Items>
      </Navbar.Wrapper>
        )
} 