import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import Container from "./atoms/Container";
import Item from "./atoms/Item";
import styled from "styled-components";

const SortButton = styled.button`
  color: white;

  background-color: ${(props) =>
    props.isSelected ? "rgb(171, 0, 51);" : "black"};
  padding: 0.3em 0.5em;
  margin: auto;

  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`;

const SortContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 10px;

  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
`;

const pagesNumber = (dataAmount) => {
  return dataAmount / 20;
};

export default function Post() {
  const [postData, setPost] = useState(null);
  const [dataAmount, setDataAmount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [sortingType, setSortingType] = useState("date desc");

  const getPages = () =>
    `${20 * selectedPage}...${20 * (selectedPage + 1) - 1}`;

  const [site, setSite] = useState(getPages(0));

  useEffect(() => {
    setSite(getPages(selectedPage));
  }, [selectedPage]);

  useEffect(() => {
    sanityClient
      .fetch(`count(*[_type == "occasional"])`)
      .then((data) => setDataAmount(data))
      .catch(console.error);
  }, [sortingType, site]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "occasional"] | order(${sortingType}) [${site}] {
            title, 
            slug,
            date,
            "placeName": place->name,
        }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, [sortingType, site]);

  const sortByDateDesc = () => setSortingType("date desc");
  const sortByDateAsc = () => setSortingType("date asc");
  const sortByTitleeDesc = () => setSortingType("title desc");
  const sortByTitleeAsc = () => setSortingType("title asc");

  const PagesButtons = ({ dataAmount }) => {
    const pageArray = [];

    for (let i = 0; i < pagesNumber(dataAmount); i++) {
      pageArray.push(i);
    }

    return pageArray.map((page) => (
      <SortButton
        key={page}
        onClick={() => setSelectedPage(page)}
        isSelected={page === selectedPage}
      >
        {page + 1}
      </SortButton>
    ));
  };

  return (
    <Container>
      <section className="container mx-auto">
        <SortContainer>
          <SortButton onClick={sortByDateDesc}>Data rosnąco</SortButton>
          <SortButton onClick={sortByDateAsc}>Data malejąco</SortButton>
          <SortButton onClick={sortByTitleeDesc}>
            Alfabetycznie malejąco
          </SortButton>
          <SortButton onClick={sortByTitleeAsc}>
            Alfabetycznie rosnąco
          </SortButton>
        </SortContainer>
        <div className="grid gap-2">
          {postData &&
            postData.map((post) => (
              <article>
                <Link
                  to={"/okazjonalne/" + post.slug.current}
                  key={post.slug.current}
                >
                  <Item
                    name={post.title}
                    typeName={post.type}
                    place={post.placeName}
                    date={post.date}
                  />
                </Link>
              </article>
            ))}
        </div>
        <SortContainer>
          {dataAmount && <PagesButtons dataAmount={dataAmount} />}
        </SortContainer>
      </section>
    </Container>
  );
}
