import styled from "styled-components";
import WebcoFooter from "./WebcoFooter";

const StyledFooter = styled.div`
    background-color: #10B981;
    color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
`;

const InfoContainer = styled.p`
    text-align: center;
    padding: 1rem;
    height: 40px;
    display: flex;
    align-items: center;
    
    @media only screen and (max-width: 40em) {
        height: 50px;
        padding: 2rem 1rem;
    }
`;


const Footer = () => {
    return <StyledFooter><InfoContainer>Przebywając na tej stronie wyrażasz zgodę na wykorzystanie plików cookie.</InfoContainer><WebcoFooter /></StyledFooter>
}

export default Footer;