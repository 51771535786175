import styled from "styled-components";
import TypeTag from "../Tag/TypeTag";
import PlaceTag from "../Tag/PlaceTag";
import DateTag from "../Tag/DateTag";
import TagsContainer from "../TagsContainer";

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  color: black;
  background-color: white;

  width: 50%;
  padding: 1rem;
  margin: auto;

  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  @media only screen and (max-width: 40em) {
    width: 90%;
    gap: 5px;
  }
`;

const Item = ({ typeName, name, place, date }) => {
  const mapTypeValueToName = (valueToMap) => {
    if (valueToMap === "max") {
      return "MAXy";
    } else if (valueToMap === "imp") {
      return "IMPy";
    } else if (valueToMap === "df") {
      return "Długa fala";
    }

    return "";
  };

  return (
    <ItemContainer>
      {name}
      <TagsContainer>
        {place && <PlaceTag>{place}</PlaceTag>}
        {date && <DateTag>{date}</DateTag>}
        {typeName && <TypeTag>{mapTypeValueToName(typeName)}</TypeTag>}
      </TagsContainer>
    </ItemContainer>
  );
};

export default Item;
