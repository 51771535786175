import styled from "styled-components";

const StyledLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: rgb(16, 185, 129);
    text-decoration: none;
  }
`;

const GoToResultsTag = ({ url }) => {
  return (
    <StyledLink href={url} target="_blank">
      Przejdź do wyników
    </StyledLink>
  );
};

export default GoToResultsTag;
