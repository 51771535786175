import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client";
import Container from "./atoms/Container";
import StyledIframe from "./atoms/StyledIframe";
import Header from "./atoms/Header";
import PlaceTag from "./atoms/Tag/PlaceTag";
import DateTag from "./atoms/Tag/DateTag";
import GoToResultsTag from "./atoms/Tag/GoToResultsTag";
import AuthorTag from "./atoms/Tag/AuthorTag";

export default function SingleOccasionalPost() {
  const [singleOccasionalPost, setSingleOccasionalPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            date,
            _id,
            slug,
            resultUrl,
            "name": author->name,
            "authorImage": author->image,
            "placeName": place->name,
        }`
      )
      .then((data) => setSingleOccasionalPost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleOccasionalPost) return <div>Ładowanie...</div>;

  return (
    <Container>
      <article className="container shadow-lg mx-auto bg-black">
        <Header>
          {singleOccasionalPost.resultUrl && (
            <GoToResultsTag url={singleOccasionalPost.resultUrl} />
          )}
          {singleOccasionalPost.placeName && (
            <PlaceTag>{singleOccasionalPost.placeName}</PlaceTag>
          )}
          {singleOccasionalPost.date && (
            <DateTag>{singleOccasionalPost.date}</DateTag>
          )}
          <AuthorTag singlePost={singleOccasionalPost} />
        </Header>
        <StyledIframe src={singleOccasionalPost.resultUrl} />
      </article>
    </Container>
  );
}
