import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client";
import Container from "./atoms/Container";
import StyledIframe from "./atoms/StyledIframe";
import Header from "./atoms/Header";
import PlaceTag from "./atoms/Tag/PlaceTag";
import AuthorTag from "./atoms/Tag/AuthorTag";
import DateTag from "./atoms/Tag/DateTag";
import GoToResultsTag from "./atoms/Tag/GoToResultsTag";

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            date,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            resultUrl,
            body,
            "name": author->name,
            "authorImage": author->image,
            "placeName": place->name,
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost) return <div>Ładowanie...</div>;

  return (
    <Container>
      <article className="container shadow-lg mx-auto bg-black">
        <Header>
          {singlePost.resultUrl && (
            <GoToResultsTag url={singlePost.resultUrl} />
          )}
          {singlePost.placeName && <PlaceTag>{singlePost.placeName}</PlaceTag>}
          {singlePost.date && <DateTag>{singlePost.date}</DateTag>}
          <AuthorTag singlePost={singlePost} />
        </Header>
        <StyledIframe src={singlePost.resultUrl} />
      </article>
    </Container>
  );
}
