import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";
import Container from "./atoms/Container";
import Item from "./atoms/Item";
import styled from "styled-components";

const Button = styled.button`
  background-color: #151515;
  color: white;
  font-size: 18px;
  padding: 0.3em 0.5em;
  cursor: pointer;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  &:disabled {
    background-color: #10b981;
  }
`;

const ButtonGroup = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 10px;

  margin: auto;
  margin-bottom: 2rem;

  @media only screen and (max-width: 40em) {
    width: 90%;
  }
`;

export default function Post() {
  const [postData, setPost] = useState(null);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
            title, 
            date,
            slug,
            "placeName": place->name,
            typeName,
        }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  useEffect(() => {});

  const YearButtons = () => {
    const yearTable = [];
    for (let i = currentYear; i >= 2021; i--) {
      yearTable.push(i);
    }

    return yearTable.map((item) => (
      <Button disabled={year === item} onClick={() => setYear(item)}>
        {item}
      </Button>
    ));
  };

  return (
    <Container>
      <section className="container mx-auto">
        <ButtonGroup>
          <YearButtons />
        </ButtonGroup>
        <div className="grid gap-2">
          {postData &&
            postData
              .sort((a, b) => (a.date < b.date ? 1 : -1))
              .filter((post) => post.date.startsWith(year))
              .map((post) => {
                return (
                  <Link
                    to={"/wtorkowe/" + post.slug.current}
                    key={post.slug.current}
                  >
                    <Item
                      name={post.title}
                      typeName={post.typeName}
                      place={post.placeName}
                    />
                  </Link>
                );
              })}
        </div>
      </section>
    </Container>
  );
}
