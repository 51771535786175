import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import Container from "./atoms/Container";
import Card from "./atoms/Card";
import BlockContent from '@sanity/block-content-to-react'; 
import { ImageHeader } from './atoms/Header/Header';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

export default function About() {
    const [authors, setAuthors] = useState(null);

    useEffect(() => {
        sanityClient.fetch(`*[_type == "author"]{
         name, 
         email, 
         phone,
         bio,
         "authorImage": image.asset->url    
        }`).then((data) => setAuthors(data))
        .catch(console.error);
    }, [])

    const getAuthorsInfos = () => authors.map(author => <Card key={author.name}>
            <ImageHeader src={urlFor(author.authorImage).url()} alt={author.name} />
            <div className="text-lg flex flex-col justify-center ml-2 mr-1">
                <h1 className="text-3xl text-green-500 mb-4">{author.name}</h1>
                <div> 
                    {author.bio && <BlockContent blocks={author.bio} />}
                    <p className="mt-2"><b>Kontakt</b> </p>
                    <p><b>Email:</b> {author.email}</p>
                    <p><b>Telefon: </b> {author.phone}</p>
                </div>
            </div>
        </Card>)

    if (!authors) return (<div>Loading...</div>);

    return (
        <Container>
            {getAuthorsInfos()}
        </Container>
    )
}