import styled from "styled-components";

export const ImageHeader = styled.img`
    width: 20rem;
    height: 16rem;
    margin-right: 1rem;

    @media only screen and (max-width: 40em) {
        width: 10rem;
        height: 13rem;
        margin: auto;
    }
`;

export const AuthorHeader = styled.div`
    text-align: right;
    justify-content: right;
    align-items: center;
    display: flex;
    color: white;
    margin-right: 2.5rem;
    
    @media only screen and (max-width: 40em) {
        margin-right: 0.5rem;
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    align-items: center;
    align-content: space-around;
    padding: 0.5rem 0;

    @media only screen and (max-width: 40em) {
        justify-content: space-around
    }
`;

export default Header;