import React from "react";
import Container from "./atoms/Container";
import Card from "./atoms/Card";

export default function Home() {
    return (
        <Container>
                <Card>
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl text-green-500 mb-4 pb-4">Regionalne Turnieje Wtorkowe 
                         </h1>
                        <div>
                            <p><b>Kiedy?</b>  W każdy powszedni wtorek *<br />
                            <b>O której?</b>  17:30<br />
                            <b>Ilość rozdań:</b>  28-30<br />
                            <b>Gdzie?</b> <a href="https://azswratislavia.pl/kontakt/" without rel="noreferrer" target="_blank"> <i className="wo-text-decoration text-green-500">Klub AZS Wratislavia Na Grobli 30-32</i></a>, drugie piętro<br />
                            <br />
                            <i>* w drugi poniedziałek miesiąca rozgrywamy turniej KMP, w tym tygodniu nie ma turnieju we wtorek.</i>
                            </p> </div>
                    </div>
                </Card>
                <Card>
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl text-green-500 mb-4 pb-4">Cennik 
                         </h1>
                        <div>
                            <p><b>Ulgowe (juniorzy + seniorzy, w PZBS)</b>  -  15zł<br />
                            <b>Normalne</b>  -  20zł</p>
                        </div>
                    </div>
                </Card>
        </Container>
        )
} 